@import '../../../node_modules/ng-zorro-antd/ng-zorro-antd';
@import "./themes/default";

@primary-color: #444444;

:root {
  --app-theme-color-primary: @primary-color; 
  --app-theme-color-primary-darken: darken(@primary-color, 20); 
  --app-theme-color-primary-lighten: lighten(@primary-color, 10); 
  
}

// Alle variabler:  all-less-variables.less
// https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less
